import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import SaveToImage from '../button/SaveToImage';
import UploadImageButton from '../button/UploadImage';
import DetailSidebar from './DetailSidebar';
import ItemType from '../common/ItemType';

interface SidebarProp {
  currentFabricCanvas: fabric.Canvas | undefined,
};
const Sidebar: React.FC<SidebarProp> = ({
  currentFabricCanvas
}) => {
  const [openMenuDetail, setOpenMenuDetail] = useState<string>('');
  const openDetailMenu=(type:string)=>{

    openMenuDetail === type?setOpenMenuDetail(''):setOpenMenuDetail(type)
    
  }
  return (
    <>
      <div className="flex h-screen w-16 flex-col justify-between border-e bg-white">
        <div>
          <div className="inline-flex size-16 items-center justify-center">
            <span className="grid size-10 place-content-center rounded-lg bg-gray-100 text-xs text-gray-600">
              L
            </span>
          </div>
          <div className="border-t border-gray-100">
            <div className="px-2">
              <ul className="space-y-1 border-t border-gray-100 pt-4">
                <li>
                  <div onClick={()=>openDetailMenu('text')}
                    className="group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                  >
                    <i className="fa-solid fa-text"></i>
                  </div>
                </li>

                <li>
                  <div onClick={()=>openDetailMenu('image')}
                    className="group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                  >
                    <i className="fa-regular fa-image"></i>
                  </div>
                </li>
                <li>
                  <UploadImageButton currentFabricCanvas={currentFabricCanvas} />
                </li>
                <li>
                  <SaveToImage currentFabricCanvas={currentFabricCanvas} />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="sticky inset-x-0 bottom-0 border-t border-gray-100 bg-white p-2">

        </div>
      </div>
      <DetailSidebar type={openMenuDetail}/>
    </>
  )
}
export default Sidebar;