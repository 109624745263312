import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './component/header/Header';
import Sidebar from './component/sidebar/Sidebar';
import FabricCanvas from './component/canvas-editor/FabricCanvas';
import DragDropProvider from './component/drap-drop/DndProvider';

const App: React.FC = () => {
  
  return (
    <div className="bg-gray-500">
      {/* <Header /> */}
      <DragDropProvider>
      <div className='flex flex-row'>
        <FabricCanvas />
      </div>
      </DragDropProvider>
    </div>
  );
}

export default App;