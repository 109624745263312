import React from 'react';
import { useDrag } from 'react-dnd';
import DraggableItem from '../drap-drop/DraggableItem';
import DraggableText from '../drap-drop/DraggableText';

interface DetailSidebarProp {
    type: string
};
const DetailSidebar: React.FC<DetailSidebarProp> = ({
    type
}) => {
    const textList = [
        {
            id: '0',
            text: 'Happy Holiday',
            fontFamily: 'Deja Vu',
            fontSize: 36,
            fill: 'black',
            image: '/assets/images/happyholiday.png'
        },
        {
            id: '1',
            text: 'Holiday in your heart',
            fontFamily: 'Time New Roman',
            fontSize: 36,
            fill: 'black',
            image: '/assets/images/happyholiday.png'
        },
        {
            id: '2',
            text: 'Happy Holiday',
            fontFamily: 'Deja Vu',
            fontSize: 36,
            fill: 'black',
            image: '/assets/images/happyholiday.png'
        },
        {
            id: '3',
            text: 'Happy Holiday',
            fontFamily: 'Deja Vu',
            fontSize: 36,
            fill: 'black',
            image: '/assets/images/happyholiday.png'
        },
    ]

    return (
        <div className={`${type === '' ? 'invisible' : 'visible'} w-full max-w-md p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 mt-14`}>
            {type === 'text' && (
                <div className='flow-root'>
                    <ul role='list' className='divide-y divide-gray-200 dark:divide-gray-700'>
                        {textList?.map((textItem) => (
                            <DraggableText key={textItem.id} image={textItem.image} text={textItem.text} fontFamily={textItem.fontFamily} fontSize={textItem.fontSize} fill={textItem.fill} />
                        ))
                        }
                    </ul>
                </div>)}
        </div>);
}
export default DetailSidebar;