import React, { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import { useDrag, useDrop } from 'react-dnd';
import Toolbar from '../toolbar/ToolBar';
import SaveToImage from '../button/SaveToImage';
import PopupSidebar from '../sidebar/PopupSidebar';
import CircleIcon from '../../icons/circle.svg'
import Sidebar from '../sidebar/Sidebar';
import DragDropProvider from '../drap-drop/DndProvider';
import DetailSidebar from '../sidebar/DetailSidebar';
import DraggableItem from '../drap-drop/DraggableItem';
import ItemType from '../common/ItemType';

interface TextItem {
    text: string,
    fontFamily: string,
    fontSize: number,
    fill: string
}

const FabricCanvas: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const fabricCanvasRef = useRef<fabric.Canvas>();
    const [editingItem, setEditingItem] = useState<fabric.IText | fabric.Image | null>(null);
    const [popupPosition, setPopupPosition] = useState<{ x: number; y: number } | null>(null);
    const [fontList, setFontList] = useState<string[]>();
    // // Function to create custom control icons
    // const createControlIcon = (iconUrl: string) => {
    //     return (ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object) => {
    //         const size = 24; // Size of the control icon
    //         const img = new Image();
    //         img.src = iconUrl;
    //         img.onload = () => {
    //             ctx.drawImage(img, left - size / 2, top - size / 2, size, size);
    //         };
    //     };
    // };

    // // Custom control icons
    // const controlIcons = {
    //     mt: createControlIcon('/path/to/mt-icon.svg'),
    //     mb: createControlIcon('/path/to/mb-icon.svg'),
    //     ml: createControlIcon('/path/to/ml-icon.svg'),
    //     mr: createControlIcon('/path/to/mr-icon.svg'),
    //     mtr: createControlIcon('/path/to/mtr-icon.svg'),
    //     bl: createControlIcon(CircleIcon),
    //     br: createControlIcon(CircleIcon),
    //     tl: createControlIcon(CircleIcon),
    //     tr: createControlIcon(CircleIcon),
    // };
    const clear = () => {
        setEditingItem(null);
        setPopupPosition(null);
    }
    useEffect(() => {
        if (canvasRef.current) {
            fabricCanvasRef.current = new fabric.Canvas(canvasRef.current, {
                selection: true,
            });

            // Set the background color to white
            fabricCanvasRef.current.setBackgroundColor('white', () => {
                fabricCanvasRef.current?.renderAll();
            });

            const fontList = [
                { fontName: 'Arial Narrow', fontFile: 'arial_narrow_7.ttf' },
                { fontName: 'Candy', fontFile: 'Candcu.ttf' },
                { fontName: 'Deja Vu', fontFile: 'vtks_deja_vu.ttf' },
                { fontName: 'Cabin', fontFile: 'Cabin-Medium.ttf' },
                { fontName: 'Lowvetica', fontFile: 'Lowvetica.ttf' },
                { fontName: 'Helvedding', fontFile: 'Helvedding-Beta.Otf' },
                { fontName: 'Helveticrap', fontFile: 'Helveticrap.ttf' },
                { fontName: 'Hagrid-Italic', fontFile: 'Hagrid-Italic-trial.ttf' },
                { fontName: 'Brig Maven', fontFile: 'Brig_Maven.ttf' },
                { fontName: 'Sansitype Script', fontFile: 'Sansitype_Script.otf' }
            ]
            let fonts = ['Arial', 'Courier New', 'Georgia', 'Times New Roman', 'Verdana'];
            //custom icon
            fontList.forEach((e) => {
                const font = new FontFace(e.fontName, `url(/assets/fonts/${e.fontFile})`);
                font.load().then((loadedFont) => {
                    // Add the font to the document
                    document.fonts.add(loadedFont);
                }).catch((error) => {
                    console.error('Failed to load font:', error);
                });
                fonts.push(e.fontName);
            })

            setFontList(fonts)
        }
        return () => {
            fabricCanvasRef.current?.dispose();
            // window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    const updatePosition = (activeObject: fabric.IText | fabric.Image) => {
        setEditingItem(activeObject);
        const boundingRect = canvasRef.current!.getBoundingClientRect();
        setPopupPosition({
            x: activeObject.left! + boundingRect.left,
            y: activeObject.top! + boundingRect.top - 60,//activeObject.height!,
        });
    }
    useEffect(() => {
        if (canvasRef.current) {
            const handleSelection = (e: fabric.IEvent) => {
                clear();
                const target = fabricCanvasRef.current?.getActiveObject();
                if (target && target.type === 'i-text') {
                    const activeObject = target as fabric.IText;
                    updatePosition(activeObject)
                }
                if (target && target.type === 'image') {
                    const activeObject = target as fabric.Image;
                    updatePosition(activeObject)
                }
            };

            fabricCanvasRef.current?.on('selection:created', handleSelection);
            fabricCanvasRef.current?.on('selection:updated', handleSelection);
            fabricCanvasRef.current?.on('selection:cleared', () => {
                clear();
            });
            fabricCanvasRef.current?.on('object:moving', (e) => {
                const target = e.target;
                if (target && target.type === 'i-text') {
                    const activeObject = target as fabric.IText;
                    updatePosition(activeObject)
                }
                if (target && target.type === 'image') {
                    const activeObject = target as fabric.Image;
                    updatePosition(activeObject)
                }
            });
        }
    }, [editingItem]);


    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file && fabricCanvasRef.current) {
            const reader = new FileReader();
            reader.onload = (f) => {
                const data = f.target?.result as string;
                fabric.Image.fromURL(data, (img) => {
                    fabricCanvasRef.current?.add(img);
                    fabricCanvasRef.current?.renderAll();
                });
            };
            reader.readAsDataURL(file);
        }
    };



    const [{ isOver }, drop] = useDrop(() => ({
        accept: ItemType.TEXT,
        drop: (item, monitor) => {
            console.log(item)
            const clientOffset = monitor.getClientOffset();
            if (clientOffset && fabricCanvasRef.current) {
                const textItem = item as TextItem
                const canvasElement = canvasRef.current;
                const boundingRect = canvasElement!.getBoundingClientRect();
                const x = clientOffset.x - boundingRect.left;
                const y = clientOffset.y - boundingRect.top;
                // const pointer = fabricCanvasRef.current.getPointer(clientOffset);

                const text = new fabric.IText(textItem.text, {
                    left: x,
                    top: y,
                    fontSize: textItem.fontSize,
                    fontFamily: textItem.fontFamily,
                    fill: textItem.fill,
                    selectable: true,
                    hasControls: true,
                    lockScalingFlip: true, // Prevent scaling flip
                    lockRotation: false,   // Allow rotation
                });
                fabricCanvasRef.current.add(text);
            }
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    return (
        <>
            <Sidebar currentFabricCanvas={fabricCanvasRef.current} />
            <div className='w-full flex flex-col'>
                <div className='h-16'> {editingItem && (
                    <Toolbar
                        editingItem={editingItem}
                        currentFabricCanvas={fabricCanvasRef.current}
                        fontList={fontList}
                    />
                )}</div>
                <div ref={drop} className='mx-auto my-auto'>
                    <canvas ref={canvasRef} width={1280} height={720} />
                </div>
                {popupPosition && (
                    <PopupSidebar
                        editingItem={editingItem}
                        currentFabricCanvas={fabricCanvasRef.current}
                        popupPosition={popupPosition}
                    />
                )}
            </div>
        </>
    );
};

export default FabricCanvas;
